import React from 'react';
import { AppWidgetProps } from '../../../types/common';
import { PaywallWidget } from './PaywallWidget';

export default function (props: AppWidgetProps) {
  return (
    <>
      <PaywallWidget {...props} />
    </>
  );
}
